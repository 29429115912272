import { Fragment, useState } from "react"
import styled from "styled-components"

import { subHours } from "date-fns"

import { LegacyEventIcon } from "src/components/EventLog/EventIcon"
import {
  SmokeFeedbackDialog,
  TSmokeEventFeedbackData,
} from "src/components/EventLog/SmokeFeedbackDialog"
import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { CreateNoiseIncidentReportButton } from "src/components/Reports/CreateNoiseIncidentReportButton"
import { IncidentReportDialogContainer } from "src/components/Reports/IncidentReportDialogContainer"
import { usePostCigaretteReportFindingsClicked } from "src/data/analytics/queries/smokingDetectionAnalyticQueries"
import { THomeLogEvent } from "src/data/events/types/homeEventTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { DebugBox } from "src/ui/Debug/DebugBox"
import { GridTable } from "src/ui/GridTable/GridTable"
import { InternalLink } from "src/ui/Link/InternalLink"
import { LocalizedDate } from "src/ui/LocalizedDate/LocalizedDate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type HomeLogTableProps = {
  homeEvents: THomeLogEvent[]
  loading?: boolean
  home: IHome
}

export function HomeLogTable({ homeEvents, home, loading }: HomeLogTableProps) {
  const { t, langKeys } = useTranslate()

  const [showSmokeFeedbackForm, setShowSmokeFeedbackForm] = useState<
    TSmokeEventFeedbackData & { open: boolean }
  >({
    open: false,
    homeId: home.home_id,
    address: home.name,
    timezone: home.timezone,

    date: "",
    eventId: "",
    deviceId: undefined,
    area: "",
  })
  const postCigaretteReportFindingsClicked =
    usePostCigaretteReportFindingsClicked()

  const header = [
    <div key={"event"}>{t(langKeys.event)}</div>,
    <div key={"sensor"}>{t(langKeys.sensor)}</div>,
    <div key={"time"}>{t(langKeys.time)}</div>,
  ]

  function onSmokeFeedbackClicked(e: THomeLogEvent) {
    postCigaretteReportFindingsClicked.mutate({
      context: "event_log",
    })
    setShowSmokeFeedbackForm({
      open: true,
      address: home.name,
      timezone: home.timezone,
      homeId: home.home_id,

      date: e.event_time,
      eventId: "", // this needs to be supplied, but is not possible with current data; a task has been created to remedy this
      deviceId: e.metadata?.device_id,
      area: "", // events currently don't supply area data, so leaving blank for now
    })
  }

  const rows = homeEvents.map((event) => {
    return (
      <Fragment key={event.event_type + event.event_time}>
        <BodyCell event={event}>
          <Feedback
            event={event}
            onSmokeFeedbackClicked={() => onSmokeFeedbackClicked(event)}
          />
        </BodyCell>

        <DeviceCell metadata={event.metadata} />

        <LocalizedDate date={event.event_time} timezone={home.timezone} />
      </Fragment>
    )
  })

  return (
    <div>
      <GridTable
        header={header}
        rows={rows}
        templateColumns="1fr auto auto"
        loading={loading}
        useFallbackResponsiveMode
      />

      <IncidentReportDialogContainer context="event_log" />

      <SmokeFeedbackDialog
        open={!!showSmokeFeedbackForm.open}
        onClose={() => setShowSmokeFeedbackForm((d) => ({ ...d, open: false }))}
        context="event_log"
        feedbackData={showSmokeFeedbackForm}
      />
      <DebugBox data={homeEvents} minimized={true} />
    </div>
  )
}

function Feedback({
  event,
  onSmokeFeedbackClicked,
}: {
  event: THomeLogEvent
  onSmokeFeedbackClicked: () => void
}) {
  const { t, langKeys } = useTranslate()

  if (event.event_type === "noise_quieted") {
    return (
      <CreateNoiseIncidentReportButton
        variant="minimal"
        label={t(langKeys.download_incident_report_button_action)}
        renderChild={({ featureAvailable }) =>
          !featureAvailable && <UpgradeIconButton context="incident_reports" />
        }
        prefillData={{
          homeId: event.home_id,
          date: subHours(new Date(event.event_time), 2),
          duration: 3,
        }}
      />
    )
  }
  if (event.event_type === "smoking_detected") {
    return (
      <>
        <TextButton onClick={onSmokeFeedbackClicked}>
          {t(langKeys.log_feedback_event_cigarette_detection)}
        </TextButton>
      </>
    )
  }

  return null
}

function BodyCell({
  event,
  children,
}: {
  event: THomeLogEvent
  children?: React.ReactNode
}) {
  return (
    <BodyBox>
      <EventIcon event={event} />

      <div>
        <MText variant="subtitle">{event.user_representation.title}</MText>
        <MText variant="bodyS" color="secondary">
          {event.user_representation.body}
        </MText>

        {children}
      </div>
    </BodyBox>
  )
}

const BodyBox = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: ${spacing.L};
  align-items: center;
`

function EventIcon({ event }: { event: THomeLogEvent }) {
  if (!event.user_representation.icon) {
    return <LegacyEventIcon eventType={event.event_type} />
  }
  return (
    <img
      src={event.user_representation.icon}
      alt={event.user_representation.title}
      height={40}
      width={40}
    />
  )
}

function DeviceCell({ metadata }: { metadata: THomeLogEvent["metadata"] }) {
  if (!metadata?.device_id) return <div></div>

  return (
    <div>
      <InternalLink
        to={Routes.SensorsWithDeviceId.location(metadata.device_id)}
      >
        {metadata.device_name}
      </InternalLink>
    </div>
  )
}
