import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  TFetchHomeLogEventsQueryParams,
  TFetchHomeLogEventsResponse,
} from "src/data/events/types/homeEventTypes"
import { homeKeys } from "src/data/homes/queries/homeQueryCache"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchHomeEvents({
  homeId,
  params,
  options,
}: {
  homeId: string
  params?: TFetchHomeLogEventsQueryParams
  options?: UseQueryOptions<
    TFetchHomeLogEventsResponse,
    AxiosError,
    TFetchHomeLogEventsResponse,
    ReturnType<typeof homeKeys.homeEvents>
  >
}) {
  const { orgId } = useOrganization()
  async function fetchHomeEvents() {
    const response = await minutApiHttpClient.get<TFetchHomeLogEventsResponse>(
      `${API_DEFAULT}/homes/${homeId}/events`,
      { params: params }
    )
    return response.data
  }

  return useQuery({
    queryKey: homeKeys.homeEvents(orgId, homeId, params),
    queryFn: fetchHomeEvents,
    ...options,
  })
}
