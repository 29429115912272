import { useMemo, useState } from "react"
import styled from "styled-components"

import { HomeLogEventFilterDropdown } from "src/components/Homes/HomeDetails/HomeLog/HomeLogEventFilterDropdown"
import { HomeLogTable } from "src/components/Homes/HomeDetails/HomeLog/HomeLogTable"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { mapFilterGroupsToHomeEventTypes } from "src/data/events/logic/eventsLogic"
import { useFetchHomeEvents } from "src/data/events/queries/homeEventQueries"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { useFlags } from "src/hooks/useFlags"
import { spacing } from "src/ui/spacing"

type HomeLogProps = {
  homeId: string
  home: IHome
}

export function HomeLog({ homeId, home }: HomeLogProps) {
  const { limit, offset, setOffset } = useUrlPager({ initialLimit: 20 })
  const [eventFilter, setEventFilter] = useState<THomeEventFilterGroup[]>([])
  const [stitchingEnabled, setStitchingEnabled] = useState(true)

  const eventTypeFilter = useMemo(() => {
    return mapFilterGroupsToHomeEventTypes(eventFilter)
  }, [eventFilter])

  const fetchHomeEvents = useFetchHomeEvents({
    homeId,
    params: {
      limit,
      offset,
      event_type: eventTypeFilter,
      include_user_events: stitchingEnabled,
    },
  })
  const homeEvents = fetchHomeEvents.data?.home_events || []
  const totalCount = fetchHomeEvents.data?.paging.total_count || 0

  return (
    <div>
      <HomeLogEventFilterDropdown
        loading={fetchHomeEvents.isLoading}
        eventFilter={eventFilter}
        setEventFilter={setEventFilter}
      />

      {/* This is a debug feature; remove once home log endpoint is fully
      functional */}
      <StitchingCheckbox
        stitchingEnabled={stitchingEnabled}
        setStitchingEnabled={setStitchingEnabled}
      />

      <MSpacer />

      <HomeLogTable
        homeEvents={homeEvents}
        loading={fetchHomeEvents.isLoading}
        home={home}
      />

      <XLSpacer />

      <Pager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={totalCount}
      />
    </div>
  )
}

const MSpacer = styled.div`
  margin-top: ${spacing.M};
`

const XLSpacer = styled.div`
  margin-top: ${spacing.XL};
`

function StitchingCheckbox({
  stitchingEnabled,
  setStitchingEnabled,
}: {
  stitchingEnabled: boolean
  setStitchingEnabled: (value: boolean) => void
}) {
  const { debug } = useFlags()
  if (!debug) return null
  return (
    <label style={{ marginLeft: "1rem" }}>
      Use stitching
      <input
        type="checkbox"
        checked={stitchingEnabled}
        onChange={(e) => setStitchingEnabled(e.currentTarget.checked)}
      />
    </label>
  )
}
